import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

function RedeemCouponForm({ onChange, error }) {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Coupon Code
      </Typography>
      <form id="redeemForm">
      <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="coupon"
              name="coupon"
              label="Please enter your coupon code here"
              fullWidth
              autoComplete="coupon"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="amount"
              name="amount"
              label="Please enter your bill amount"
              fullWidth
              autoComplete="amount"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="comments"
              name="comments"
              label="Comments"
              fullWidth
              autoComplete="comments"
              onChange={onChange}
            />
          </Grid>
      </Grid>
      </form>
      <p style={{ color: 'red' }}>{error}</p>
    </>
  );
}

RedeemCouponForm.defaultProps = {
  error: '',
};

RedeemCouponForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default RedeemCouponForm;
