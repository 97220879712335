import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import api from '../api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  table: {
    marginTop: '50px',
  },
});

class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      partners: [],
    };
  }

  async componentDidMount() {
    try {
      const { partners } = await api.users.partners();
      if (partners) {
        this.setState({
          partners,
        });
      }
    } catch (ex) {
      alert(ex.message);
    }
  }

  render() {
    const { classes } = this.props;
    const { partners } = this.state;

    return (
      <main className={classes.content}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Role</TableCell>
              <TableCell align="right">Total Coupons</TableCell>
              <TableCell align="right">Used Coupons</TableCell>
              <TableCell align="right">Available Coupons</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partners.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.role}</TableCell>
                <TableCell align="right">{row.total}</TableCell>
                <TableCell align="right">{row.redeemed}</TableCell>
                <TableCell align="right">{row.available}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </main>
    );
  }
}

Partners.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Partners));
