import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableRow';
import { map } from 'lodash';
import api from '../api';
import styles from './../styles/generatecoupon';

class GenerateCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      data: {},
      loading: false,
      coupons: [],
      partners: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  async onSubmit(e) {
    e.preventDefault();

    const { data } = this.state;
    try {
      this.setState({ loading: true });
      const res = await api.coupons.generate(data);

      if (res.message) {
        this.setState({ coupons: res.coupons });
        alert(res.message);
      } else {
        alert(res.error);
      }
    } catch (ex) {
      alert(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    try {
      const { partners } = await api.users.partners();
      if (partners) {
        this.setState({
          partners,
        });
      }
    } catch (ex) {
      alert(ex.message);
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, partners, coupons } = this.state;

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Generate Coupons
          </Typography>
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="firstName"
                  name="count"
                  label="How many?"
                  fullWidth
                  autoComplete=""
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="firstName"
                  name="prefix"
                  label="Prefix (Optional)"
                  fullWidth
                  autoComplete=""
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControl} fullWidth>
                  {/* <InputLabel htmlFor="partner-helper">Partner</InputLabel> */}
                  <Select
                    value={this.state.data.partner_id}
                    onChange={this.onChange}
                    input={<Input name="partner_id" id="partner-helper" />}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {map(partners, partner => (
                      <MenuItem value={partner.id}>{partner.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Select the partner account to generate coupons
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {loading ? (
                    <CircularProgress size={24} className="progress-bar" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        {coupons.length > 0 && (
          <Paper>
            <Table>
              <TableHead>
                <TableCell>Coupons</TableCell>
              </TableHead>
              <TableBody>
                {map(coupons, c => (
                  <TableRow>
                    <TableCell>{c}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </main>
    );
  }
}

export default withStyles(styles)(GenerateCoupon);
