import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import api from './../api';
import { each } from 'lodash';
import styles from './../styles/createuser';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      data: {},
      loading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  formData = new FormData();

  handleFile = e => {
    this.formData.append('image', e.target.files[0]);
    window.t = this.formData;
  };

  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  async onSubmit(e) {
    e.preventDefault();
    const { data } = this.state;
    try {
      this.setState({ loading: true });
      each(data, (item, key) => this.formData.append(key, item));
      const res = await api.users.create(this.formData);

      if (res.message) {
        alert(res.message);
      } else {
        alert(res.error);
      }
    } catch (ex) {
      alert(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Create User
          </Typography>
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="firstName"
                  name="name"
                  label="Name"
                  fullWidth
                  autoComplete=""
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="firstName"
                  name="password"
                  label="Password"
                  fullWidth
                  autoComplete=""
                  type="password"
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="firstName"
                  name="password"
                  label="Picture"
                  fullWidth
                  autoComplete=""
                  type="file"
                  onChange={this.handleFile}
                />
                <FormHelperText>
                  Select an image for partner account. *
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {loading ? (
                    <CircularProgress size={24} className="progress-bar" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(CreateUser);
