import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function CouponStatus(props) {
  const { coupon } = props;
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {coupon.status === 'AVAILABLE' ? 'Available!' : 'Oops!'}
      </Typography>
      <Typography variant="subtitle1">
        {coupon.status === 'AVAILABLE'
          ? `The coupon ${
              coupon.code
            } is available. Please click Next to redeem this coupon. Note: The coupon can only be redeemed once.`
          : `The coupon has already been redeemed on ${
              coupon.redemption_date
            }. Note: A coupon can only be redeemed once.`}
      </Typography>
    </>
  );
}

CouponStatus.propTypes = {
  coupon: PropTypes.shape({
    code: PropTypes.string,
    redemption_date: PropTypes.string,
  }).isRequired,
};

export default CouponStatus;
