import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.scss';
import SignIn from './containers/SignIn';
import Dashboard from './containers/Dashboard'
import PrivateRoute from './components/PrivateRoute';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login" component={SignIn} />
          {/* Sub sections in dashboard are routed internally */}
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </Router>
    );
  }
}

export default App;
