import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { map } from 'lodash';
import api from '../api';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
});

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      partners: [],
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const { partners } = await api.users.partners();
      if (partners) {
        this.setState({
          partners,
        });
      }
    } catch (ex) {
      alert(ex.message);
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async onSubmit(e) {
    e.preventDefault();

    try {
      const { from, to, partner_id } = this.state;
      const res = await api.reports.generate({
        from,
        to,
        partner_id,
      });
      if (res.file) {
        window.open(
          `${window.location.protocol}//${window.location.host}/api/files/${res.file}`
        );
      } else {
        alert('Something went wrong');
      }
    } catch (ex) {
      alert(ex.message);
    }
  }

  render() {
    const { classes } = this.props;
    const { partners, loading } = this.state;

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Generate Reports
          </Typography>
          <form id="report-form" onSubmit={this.onSubmit}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="date-from"
                label="Date From:"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="from"
                onChange={this.onChange}
              />
              <TextField
                required
                id="date-to"
                label="Date To:"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="to"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="partner-helper" shrink >Partner</InputLabel>
                <Select
                  value={this.state.partner_id}
                  onChange={this.onChange}
                  input={<Input name="partner_id" id="partner-helper" required/>}
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {map(partners, partner => (
                    <MenuItem value={partner.id}>{partner.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? (
                  <CircularProgress size={24} className="progress-bar" />
                ) : (
                  'Generate'
                )}
              </Button>
            </Grid>
          </form>
        </Paper>
      </main>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Reports));
