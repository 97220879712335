import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RedeemCouponForm from './../components/RedeemCouponForm';
import CouponStatus from './../components/CouponStatus';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from './../api';
import styles from './../styles/redeem';

const steps = ['Enter Coupon', 'Redeem Coupon'];

class Redeem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      data: {},
      coupon: null,
      loading: false,
    };

    this.handleNext = this.handleNext.bind(this);
  }

  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  async handleNext() {
    try {
      this.setState({ loading: true });
      if (this.state.activeStep === 0) {
        const redeemForm = document.querySelector('#redeemForm')
        if (!redeemForm.reportValidity()) {
          return false;
        }
        
        const { coupon } = await api.coupons.search({
          code: this.state.data.coupon,
        });
        if (coupon) {
          this.setState(state => ({
            activeStep: state.activeStep + 1,
            coupon,
          }));
        } else {
          this.setState({
            error: 'Coupon code does not exist',
          });
        }
      } else if (this.state.activeStep === 1) {
        const res = await api.coupons.redeem({
          code: this.state.data.coupon,
          amount: this.state.data.amount,
          comments: this.state.data.comments,
        });
        if (res.message) {
          this.setState(state => ({
            activeStep: state.activeStep + 1,
            message: res.message,
          }));
        } else {
          alert(res.error);
        }
      }
    } catch (ex) {
      alert(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleBack = () => {
    window.location.reload();
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <RedeemCouponForm onChange={this.onChange} error={this.state.error} />
        );
      case 1:
        return <CouponStatus coupon={this.state.coupon} />;
      default:
        throw new Error('Unknown step');
    }
  }

  showNextButton() {
    const { classes } = this.props;
    const { activeStep, coupon, loading } = this.state;

    if (activeStep < 2 && (!coupon || coupon.status === 'AVAILABLE')) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleNext}
          className={classes.button}
        >
          {loading ? (
            <CircularProgress size={24} className="progress-bar" />
          ) : (
            'Next'
          )}
        </Button>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;
    const image = sessionStorage.getItem('image');

    return (
      <>
        {image !== 'null' && (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <img style={{ maxHeight: '200px' }} src={'/api/' + image} />
          </div>
        )}
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Redeem
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <>
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Successfully redeemded!
                  </Typography>
                  <Typography variant="subtitle1">
                    {this.state.message}
                  </Typography>
                  {activeStep !== 0 && (
                    <Button
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                </React.Fragment>
              </>
            ) : (
              <>
                {this.getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                  {this.showNextButton()}
                </div>
              </>
            )}
          </Paper>
        </main>
      </>
    );
  }
}

Redeem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Redeem);
