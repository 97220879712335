import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../api';
import setAuthorizationHeader from '../utils/setAuthorizationHeader';
import styles from '../styles/signin';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async onLoginClick(e) {
    e.preventDefault();
    const { history } = this.props;
    const { username, password } = this.state;

    try {
      this.setState({ loading: true });
      const res = await api.users.login({
        username,
        password,
      });

      if (res.token) {
        sessionStorage.setItem('authToken', res.token);
        sessionStorage.setItem('role', res.role);
        sessionStorage.setItem('image', res.image);
        setAuthorizationHeader(sessionStorage.authToken);
        history.push('/');
      } else {
        this.setState({
          error: 'Invalid username/password combination',
        });
      }
    } catch (ex) {
      alert(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, error } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={this.onLoginClick}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="name">Username</InputLabel>
              <Input
                id="name"
                name="username"
                autoComplete="name"
                autoFocus
                onChange={this.onChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.onChange}
              />
            </FormControl>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <p className={classes.error}>{error}</p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loading ? (
                <CircularProgress size={24} className="progress-bar" />
              ) : (
                'Sign in'
              )}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(SignIn);
