import axios from 'axios';

export default {
    users: {
        login: (credentials) => axios.post('/api/', {
            cmd: 'LOGIN',
            ...credentials
        }).then(res => res.data),
        // create: (params) => axios.post('/api/', { cmd: 'CREATEUSER', ...params }).then(res => res.data),
        create: (formData) => {
            formData.append('cmd', 'CREATEUSER');
            return axios.post('/api/', formData).then(res => res.data);
        },
        partners: () => axios.post('/api/', {
            cmd: 'GETPARTNERS'
        }).then(res => res.data),
    },
    coupons: {
        generate: (params) => axios.post('/api/', {
            cmd: 'GENERATECOUPON',
            ...params
        }).then(res => res.data),
        search: (params) => axios.post('/api/', {
            cmd: 'SEARCHCOUPON',
            ...params
        }).then(res => res.data),
        redeem: (params) => axios.post('/api/', {
            cmd: 'REDEEMCOUPON',
            ...params
        }).then(res => res.data),
    },
    reports: {
        generate: (params) => axios.post('/api/', {
            cmd: 'GENERATEREPORT',
            ...params
        }).then(res => res.data),
    }
}